<script setup>
    import { ref } from "vue";
    import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
    import axios from "axios";

    const verified = ref(false);
    //const expired = ref(false);
    const token = ref("");
    const eKey = ref("");
    //const error = ref("");
    //const invisibleHcaptcha = ref(null);
    //const asyncExecuteHCaptcha = ref(null);
    const formname = ref();
    const formemail = ref();
    const formmessage = ref();

    function onVerify(tokenStr, ekey) {
        verified.value = true;
        token.value = tokenStr;
        eKey.value = ekey;
        console.log(`Callback token: ${tokenStr}, ekey: ${ekey}`);
    }

    function submit() {
        if (!verified.value) {
            alert("please confirm you are not a robot")
            return false
        }
        else {
            var name = formname.value
            var email = formemail.value
            var message = formmessage.value
            var errors = "";
            document.getElementById("submit").value = 'Sending...';
            document.getElementById("submit").setAttribute("disabled", true);
            //if (email == "" || msg == "" || name == "") {
            //    valid = false;
            //    errors = "All fields are required.";
            //}
            if (verified.value) {
                console.log(name + " " + email + " " + message)
                axios.post("https://samvedamanikam.com/heliosengine/api/Mavali/sendEmail", {
                    email: email,
                    message: message,
                    full_name: name
                }).then(function (res) {
                    console.log(res)
                    document.getElementById("submit").value = 'Sent!';
                })
                .catch(function (error) {
                    console.log(error)
                })
            } else {
                this.errorMessage = errors;
            }
        
        }
    }
</script>

<template>
    <!--<img alt="Vue logo" src="./assets/logo.png">
    //<HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <section class="preloader">
        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </section>

    <!-- home section -->
    <section id="home" >
        <div class="container">
            <div class="row">

                <div class="col-md-offset-2 col-md-8 col-sm-12">
                    <div class="home-thumb">
                        <img src="@/assets/images/mavali_transparent.png" class="img-responsive wow" alt="About" style="margin-top:-375px;">
                        <h2 class="wow fadeInUp" data-wow-delay="0.4s">SERVING THE AUSTIN AREA SINCE 2014</h2>

                        <!--<h3 class="wow fadeInUp" data-wow-delay="0.6s">Serving the <strong>Austin</strong> area since 2001.</h3>-->
                        <a href="#about" class="btn btn-lg btn-default wow fadeInUp hidden-xs" data-wow-delay="0.8s">Let's begin</a>
                        <!--<a href="#" data-toggle="modal" data-target="#modal1" class="btn btn-lg btn-success smoothScroll wow fadeInUp" data-wow-delay="1.0s">Notify me!</a>-->
                    </div>
                </div>

            </div>
        </div>
    </section>

    <!-- about section -->
    <section id='about'>
        <div class="container">
            <div class="row">
                
                <!--<div class="col-md-12 col-sm-12">
                    <img src="@/assets/images/mavali.jpg" class="img-responsive wow fadeInUp" alt="About">
                </div>-->

                <div class="col-md-12 col-sm-12">
                    <div class="about-thumb">
                        <div class="section-title">
                            <!--<h1 class="wow fadeIn" data-wow-delay="0.2s">Mavali Logistics Services</h1>-->
                            <h1 class="wow fadeInUp" data-wow-delay="0.4s">About Our Company</h1>
                        </div>
                        <div class="wow fadeInUp" data-wow-delay="0.6s">
                            <p>We started our borkerage business in 2014, but have been in the trucking industry since 2008. We strive to provide the best in class service to all of our customers.</p>
                            <p>We are your premier destination for comprehensive and reliable truck brokerage services, with a specialized focus on flatbed trucking. In an industry where versatility and precision matter, we stand out as a leader, connecting shippers and carriers with tailored solutions that meet the unique demands of flatbed transportation.</p>
                            <p>Our carrier company M&A Trucking inc can also handle all of your flatbed, dry vans, and refrigerated trailer (reefers) needs. Our flatbed services can haul all types of commodities, including lumber, stone, construction equipment and materials.</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- feature section -->
    <section id="feature">
        <div class="container">
            <div class="row">

                <svg preserveAspectRatio="none" viewBox="0 0 100 102" height="100" width="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" class="svgcolor-light">
                    <path d="M0 0 L50 100 L100 0 Z"></path>
                </svg>

                <div class="col-md-4 col-sm-6">
                    <div class="media wow fadeInUp" data-wow-delay="0.4s">
                        <div class="media-object media-left">
                            <i class="fa fa-users" aria-hidden="true"  style="color:white; font-size:48px;"></i>
                        </div>
                        <div class="media-body">
                            <h2 class="media-heading">Freight Broker</h2>
                            <p>We facilitate all aspects of transportation logistics. Our company strives to make the process effortless.</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="media wow fadeInUp" data-wow-delay="0.8s">
                        <div class="media-object media-left">
                            <i class="fa fa-truck" aria-hidden="true" style="color:white; font-size:48px;"></i><!--<i class="icon icon-mobile"></i>-->
                        </div>
                        <div class="media-body">
                            <h2 class="media-heading">Flatbed / Dry vans / Reefers</h2>
                            <p>Our carrier company, M&A Trucking inc. has been in business since 2008! Let us help you with all of your transportation needs.</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-8">
                    <div class="media wow fadeInUp" data-wow-delay="1.2s">
                        <div class="media-object media-left">
                            <i class="fa fa-comments" aria-hidden="true"  style="color:white; font-size:48px;"></i><!--<i class="icon icon-chat"></i>-->
                        </div>
                        <div class="media-body">
                            <h2 class="media-heading">Customer Support</h2>
                            <p>we are always ontime! We will help you in all aspects of the job. Bilingual in Spanish and English.</p>
                        </div>
                    </div>
                </div>

                <div class="clearfix text-center col-md-12 col-sm-12">
                    <a href="#contact" class="btn btn-default ">Talk to us</a>
                </div>

            </div>
        </div>
    </section>

    <!-- contact section -->
    <section id="contact">
        <div class="container">
            <div class="row">

                <div class="col-md-offset-2 col-md-8 col-sm-12">
                    <div class="section-title">
                        <h1 class="wow fadeInUp" data-wow-delay="0.3s">Get in touch</h1>
                        <p class="wow fadeInUp" data-wow-delay="0.6s">Contact us today. We will get in touch with you as soon as possible.</p>
                    </div>
                    <div class="contact-form wow fadeInUp" data-wow-delay="1.0s">
                        <form id="contact-form">
                            <div class="col-md-6 col-sm-6">
                                <input v-model="formname" name="name" type="text" class="form-control" placeholder="Your Name" required>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <input v-model="formemail" name="email" type="email" class="form-control" placeholder="Your Email" required>
                            </div>
                            <div class="col-md-12 col-sm-12">
                                <textarea v-model="formmessage" name="message" class="form-control" placeholder="Message" rows="6" required></textarea>
                            </div>
                            <div class="col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8">
                                <input name="submit" type="submit" class="form-control submit" id="submit" value="SEND MESSAGE" @click="submit()">
                            </div>
                        </form>
                        <vue-hcaptcha sitekey="ce85f66f-db1b-41ae-af57-3c654a9fbad3"
                                       @verify="onVerify"></vue-hcaptcha>
                        
                    </div>
                </div>

            </div>
        </div>
    </section>

    <!-- footer section -->
    <footer>
        <div class="container">
            <div class="row">

                <svg class="svgcolor-light" preserveAspectRatio="none" viewBox="0 0 100 102" height="100" width="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0 L50 100 L100 0 Z"></path>
                </svg>

                <div class="col-md-4 col-sm-6">
                    <h2>Mavali Logistics Services LLC</h2>
                    <div class="wow fadeInUp" data-wow-delay="0.3s">
                        <p>All rights reserved</p>
                        <p class="copyright-text">
                            Copyright &copy; 2023 Mavali Logistics Services <br>
                            Design: Templatemo <br />
                            Developer: <a href="http://samvedamanikam.com" target="_blank">Sam Vedamanikam</a>
                        </p>
                    </div>
                </div>

                <div class="col-md-4 col-sm-5"></div>

                <!--<div class="col-md-4 col-sm-5">
                    <h2>Social Media</h2>

                    <ul class="social-icon">
                        <li><a href="#" class="fa fa-facebook wow bounceIn" data-wow-delay="0.9s"></a></li>
                    </ul>
                </div>-->

                <div class="col-md-4 col-sm-5">
                    <h2>Direct Contact</h2>
                    <p class="wow fadeInUp" data-wow-delay="0.6s">
                        <a href="https://www.google.com/maps/search/?api=1&query=4005%20Prairie%20Ln%2C%20Austin%2C%20Tx" target=�_blank�> 4005 Prairie Ln, Austin, Tx</a><br>
                        <a href="mailto: mavalilogistics@gmail.com">mavalilogistics@gmail.com</a><br>
                        <a href="mailto: mandatruckinginc@gmail.com">mandatruckinginc@gmail.com</a><br>
                        <a href=�tel:1-512-845-5799�>512-845-5799 (Dispatch)</a><br>
                        <a href=�tel:1-512-845-5799�>512-203-5030 (Dispatch)</a><br>
                        <a href=�tel:1-512-845-5799�>737-977-4868 (Account)</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>

    <!-- modal -->
    <!--<div class="modal fade" id="modal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content modal-popup">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h2 class="modal-title">Our Newsletter</h2>
                </div>
                <form action="#" method="post">
                    <input name="fullname" type="text" class="form-control" id="fullname" placeholder="Full Name">
                    <input name="email" type="text" class="form-control" id="email" placeholder="Email Address">
                    <input name="submit" type="submit" class="form-control" id="submit" value="Subscribe Now">
                </form>
                <p>Thank you for your visiting!</p>
            </div>
        </div>
    </div>-->


    <!-- Back top -->
    <a href="#back-top" class="go-top"><i class="fa fa-angle-up"></i></a>

</template>


<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
    name: 'App',
     components: {
    //HelloWorld
        },
        data() {
            return {
                formname: "",
                formemail: "",
                formmessage: "",
                errorMessage: ""
            }
        },
        mounted() {
        },
    methods: {
        //submit: function () {
        //    if (!verified) {
        //        alert("please confirm you are not a robot")
        //        return false
        //    }
        //    else {
        //        alert("verified")
        //        return true
        //    }
            //var success = 0
            //var name = this.contactName
            //var phone = this.contactphone
            //var email = this.contactEmail
            //var msg = this.contactMessage
            //var errors = "";
            //document.getElementById("sendMessageButton").innerHTML = 'Sending...';
            //document.getElementById("sendMessageButton").setAttribute("disabled", true);
            //if (email == "" || msg == "" || name == "" || phone == "") {
            //    valid = false;
            //    errors = "All fields are required.";
            //}
            //if (verified) {

            //    axios.post("https://samvedamanikam.com/heliosengine/api/Fulham/sendEmail", {
            //        email: this.contactEmail,
            //        message: this.contactMessage,
            //        full_name: this.contactName,
            //        phone: this.contactphone,
            //        whatsapp: whatsapp
            //    }).then(function (res) {
            //        console.log(res)
            //        success = success + 1
            //        document.getElementById("sendMessageButton").innerHTML = 'Sent!';
            //    })
            //        .catch(function (error) {
            //            console.log(error)
            //        })
            //    this.executeRecaptcha()
            //    // ajax to the php file to send the mail
            //    axios.post("https://samvedamanikam.com/heliosengine/api/recaptcha/validateFulhamTX", {
            //        email: this.contactEmail,
            //        message: this.contactMessage,
            //        name: this.contactName,
            //        phone: this.contactphone,
            //        whatsapp: whatsapp,
            //        grecaptcharesponse: response
            //    })
            //        .then(response => {
            //            console.log(response.data)
            //            success = success + 1
            //        })
            //        .catch(function (error) {
            //            console.log(error)
            //            if (success > 0) {
            //                this.show = !this.show
            //                this.contactName = "";
            //                this.contactEmail = "";
            //                this.contactMessage = "";
            //                this.contactphone = ""
            //                document.getElementById("sendMessageButton").innerHTML = 'Sent!';
            //            } else {
            //                document.getElementById("sendMessageButton").innerHTML = 'Error!';
            //            }
            //        })
            //    if (success > 0) {
            //        this.show = !this.show
            //        this.contactName = "";
            //        this.contactEmail = "";
            //        this.contactMessage = "";
            //        this.contactphone = ""
            //        document.getElementById("sendMessageButton").innerHTML = 'Sent!';
            //    } else {
            //        document.getElementById("sendMessageButton").innerHTML = 'Error!';
            //    }
            //} else {
            //    this.errorMessage = errors;
            //}
        //}
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

    @import './assets/css/bootstrap.min.css';
    @import './assets/css/animate.min.css';
    @import './assets/css/et-line-font.css';
    @import './assets/css/font-awesome.min.css';
    @import './assets/css/vegas.min.css';
    @import './assets/css/style.css';
    @import 'https://fonts.googleapis.com/css?family=Rajdhani:400,500,700';
</style>

